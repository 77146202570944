var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-popover",
        { attrs: { placement: "bottom", width: "600", trigger: "click" } },
        [
          _c(
            "el-badge",
            {
              attrs: {
                slot: "reference",
                "is-dot": _vm.unreadCount > 0,
                type: "danger",
              },
              slot: "reference",
            },
            [
              _c("svg-icon", {
                attrs: { "icon-class": "message" },
                on: { click: _vm.getList },
              }),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: { data: _vm.list },
            },
            [
              _c("el-table-column", {
                attrs: {
                  width: "120",
                  property: "templateNickname",
                  label: "发送人",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "180",
                  property: "createTime",
                  label: "发送时间",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [
                          _vm._v(_vm._s(_vm.parseTime(scope.row.createTime))),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  label: "类型",
                  align: "center",
                  prop: "templateType",
                  width: "100",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("dict-tag", {
                          attrs: {
                            type: _vm.DICT_TYPE.SYSTEM_NOTIFY_TEMPLATE_TYPE,
                            value: scope.row.templateType,
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { property: "templateContent", label: "内容" },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { "text-align": "right", "margin-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.goMyList },
                },
                [_vm._v("查看全部")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }