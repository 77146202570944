var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.enable()
    ? _c(
        "el-alert",
        { attrs: { type: "success", "show-icon": "" } },
        [
          _c("template", { slot: "title" }, [
            _c("div", { on: { click: _vm.goToUrl } }, [
              _vm._v(_vm._s("【" + _vm.title + "】文档地址：" + _vm.url)),
            ]),
          ]),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }